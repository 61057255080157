import React, { Component } from "react";
import{GiLaurelsTrophy} from "react-icons/gi";
import "./About.css";

export default class About extends Component {
  render() {
    return (
      <div class="about">
        <div class="about__item1">
          <img src={this.props.picture} alt="text" />
        </div>
        <div class="about__item2">
          <p class="heading">{this.props.heading}</p>
          <p>{this.props.despc}</p>
          <br />

          <p class="subtext"><GiLaurelsTrophy/>{this.props.despc1}</p>
          <br />
          <p class="subtext"><GiLaurelsTrophy/>{this.props.despc2}</p>
          <br />
          <p class="subtext"><GiLaurelsTrophy/>{this.props.despc3}</p>
          <br />

          <br />
        </div>
      </div>
    );
  }
}
