import React, { Component } from "react";
import "./Portfolio.css";
import './Carousel.css'; // Path to your Carousel component
import "slick-carousel/slick/slick.css"; // Import Slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Optional theme styles

export default class portfolio extends Component {
  render() {
    return (
      
      <div >
        <div className="wrapper">
        <div className="portfolio__wrapper">
          
          <div className="portfolio_items p2"></div>
          
          <div className="portfolio_items p5"></div>
          <div className="portfolio_items p8"></div>
          <div className="portfolio_items p1"></div>
          <div className="portfolio_items p12"></div>
          <div className="portfolio_items p10"></div>
          <div className="portfolio_items p9"></div>
          
          <div className="portfolio_items p13"></div>
          <div className="portfolio_items p14"></div>
          <div className="portfolio_items p15"></div>
          <div className="portfolio_items p16"></div>
          <div className="portfolio_items p3"></div>
          <div className="portfolio_items p4"></div>
          <div className="portfolio_items p11"></div>
          
          <div className="portfolio_items p2"></div>
         
          <div className="portfolio_items p5"></div>
          <div className="portfolio_items p8"></div>
          <div className="portfolio_items p1"></div>
          <div className="portfolio_items p12"></div>
          <div className="portfolio_items p10"></div>
          <div className="portfolio_items p9"></div>
          <div className="portfolio_items p3"></div>
          <div className="portfolio_items p4"></div>
          <div className="portfolio_items p11"></div>
          <div className="portfolio_items p13"></div>
          <div className="portfolio_items p14"></div>
          <div className="portfolio_items p15"></div>
          <div className="portfolio_items p16"></div>
          
          
        </div>
        
        
        
        </div>
        
        <div className="portfolio_wrapper_button">
          {/* <button
            className="Portfolio_button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://portfolio.techsnapie.com/";
            }}
          >
            Explore <span>More</span>
          </button> */}
        </div>
      </div>
    );
  }
}
