import React from 'react'
import ReactDOM from 'react-dom'
import "./App.css";
import { Element } from "react-scroll";
import p1 from "./About/icons/remotly.png";
import p2 from "./About/icons/skech.png";
import Navbar from "./navbar/Navbar";
import Home from "./home/Home";
import Title from "./Title/Title";
import About from "./About/About";
import Services from "./Services/Services";
import Servicepill from "./Services/Servicepill";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Portfolio from "./Portfolio/Portfolio";
import wdl from "./Services/web-design.png";
import mdl from "./Services/mobile-app-development.png";
import ddl from "./Services/desktop-app-development.png";
import uidl from "./Services/ui-ux-design.png";
import pro1 from "./Services/billboard.png";
import pro2 from "./Services/jewelry.png"

function App() {
  return (
    <div class="layout">
      <Navbar />
      <Home />
      <Element id="about">
        <Title
          t1="About"
          t2=" Us"
          subtitle="DELIVERING INNOVATIONS"
          data-aos={"fade-left"}
        />
      </Element>
      <About
        heading={["What We ", <span>Do</span>, "?"]}
        picture={p1}
        despc="We're developers who enjoy developing custom websites and applications to help businesses do better online. We're here to unleash the world's innovation spark by creating a more enlightened way of working. We have highly dedicated informative Developers, UI/UX Designers, Digital Marketer, Security Analyst that we will make your business stand unique in this society."
        despc1=" Provide a unique solution for your problem using our best technology"
        despc2=" Provide innovative designs which will be most attractive"
        despc3=" Provide top-rating user-friendly tools"
        order="1"
      />
      <About
        heading={["How We ", <span>Do</span>, " ?"]}
        picture={p2}
        despc1=" We start grasping the concept of the business and select the right techniques."
        despc2=" To grab the attention of the customers of our client."
        despc3=" Finally, we endow an idiosyncratic solution."
        order="2"
      />
      <Element id="services">
        <div className="yellow--bg">
          <Title t1="Serv" t2="ices" subtitle="WELL DONE IS OUR TARGET" />
          <div className="card__container">
            <Services
              card_title="Web development"
              card_desp="We don't just build websites,we build websites that sells."
              img={wdl}
            />
            <Services
              card_title="Mobile app development"
              card_desp="We design and develop experiencs that make people's lives simple."
              img={mdl}
            />
            <Services
              card_title="Desktop app development"
              card_desp="We are responsible artists, whether it is design or development."
              img={ddl}
            />
            <Services
              card_title="Ui/Ux Design"
              card_desp=" Digital design is like painting, except the paint never dries."
              img={uidl}
            />
            <Services
              card_title="Digital Marketing"
              card_desp=" Digital marketing is the art of storytelling in a digital world"
              img={uidl}
            />
            <Services
              card_title="Graphical Designing"
              card_desp=" Digital design is like painting, except the paint never dries."
              img={uidl}
            />
          </div>
          <p className="service__pill__title">We Also Do..</p>
          <div className="card__container">
            <Servicepill pilltitle="SEO" />
            <Servicepill pilltitle="Digital Marketing" />
            <Servicepill pilltitle="Poster" />
            <Servicepill pilltitle="Logo Design" />
            <Servicepill pilltitle="E-Certification" />
            <Servicepill pilltitle="Pen-testing" />
            <Servicepill pilltitle="Functionality Testing" />
            <Servicepill pilltitle="YT Campaigns" />
            <Servicepill pilltitle="Hosting" />
          </div>
        </div>
      </Element>

      <Element id="services">
  <div className="yellow--bg">
    <Title t1="Pro" t2="ducts" subtitle="Our Products" />
    <div className="card__container">
      <Services
        card_title="SnapAds Marketing Software"
        card_desp="Innovative outdoor advertising software designed specifically for advertising agencies."
        img={pro1}
      />
      
    </div>
    
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <button
      className="redirect__button"
        onClick={() => {
          window.location.href = "https://snapads.in/";
        }}
      >
        Visit SnapAds
      </button>
</div>
  </div>
</Element>







      <Element id="portfolio">
        <Title t1="Our " t2= " Clients" subtitle="MAKING IDEAS COME ALIVE" />
        <p className="Portfolio__heading">
          "Making our clients Satisfied".
        </p>

        <Portfolio />
      </Element>

      <Element id="contact">
        <div className="yellow--bg">
          <Title t1="Contact" t2=" Us" subtitle="GET TOUCH WITH US" />
          <Contact
            details={[
              "D325,near SNS college of Engineering, Vaiyapuri Nager, saravanampatti, Coimbatore, TamilNadu, India.",
              "contact@techsnapie.com",
              "+91 86681-79904",
            ]}
          />
        </div>
      </Element>
      <Footer />
    </div>
  );
}

export default App;
