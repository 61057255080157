import React, { Component } from "react";
import "./Footer.css";
import tslogo from "./ts__logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer__wrapper">
        <div className="footer">
          <div className="footer__content">
            <div className="item1__container">
              <div>
                <img src={tslogo} alt="" width={"30px"} height={"30px"} />
                <p className="text">&nbsp;&nbsp;TechSnapie Solutions</p>
             
              </div>
              <p className="subtext">
                Techsnapie Solutions
                <br />
                Coimbatore,
                <br />
                TamilNadu, India. <br />
                <br />
                contact@techsnapie.com <br />
                86681-79904
              </p>
            </div>
          </div>
          <div className="footer__content">
            <div className="footer__heading">Useful Links</div>
            <p className="footer__items">
              <Link to="about" smooth={true} duration={500}>
                About Us
              </Link>
            </p>
            <p className="footer__items">
              <Link to="services" smooth={true} duration={500}>
                Services
              </Link>
            </p>
            <p className="footer__items">
              <Link to="portfolio" smooth={true} duration={500}>
                Portfolio
              </Link>
            </p>
            <p className="footer__items">
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </p>
            <p
              className="footer__items"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "http://portfolio.techsnapie.com/privacy-policy.html";
              }}
            >
              Privacy policy
            </p>
          </div>
          <div className="footer__content">
            <div className="footer__heading">Our Services</div>
            <p className="footer__items">
              <Link to="services" smooth={true} duration={500}>
                Web development
              </Link>
            </p>
            <p className="footer__items">
              <Link to="services" smooth={true} duration={500}>
                App development
              </Link>
            </p>
            <p className="footer__items">
              <Link to="services" smooth={true} duration={500}>
                Digital marketing
              </Link>
            </p>
            <p className="footer__items">
              <Link to="services" smooth={true} duration={500}>
                Graphics designing
              </Link>
            </p>
          </div>
          <div className="footer__content">
            <div className="footer__heading">Social Links</div>
            <div className="social__icons__container">
              <div
                className="social__icons"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://instagram.com/techsnapieofficial?igshid=N2ZiY2E3YmU=";
                }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </div>
              <div
                className="social__icons"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://www.facebook.com/techsnapie.techsnapie.1";
                }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </div>
              <div
                className="social__icons"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://www.youtube.com/@teamtechsnapie5810";
                }}
              >
                <FontAwesomeIcon icon={faYoutube} />
              </div>
              <div
                className="social__icons"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://www.linkedin.com/company/techsnapie/";
                }}
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </div>

              <div
                className="social__icons"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://twitter.com/techsnapieoffi1";
                }}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
