import { useForm, ValidationError } from '@formspree/react';
import './Contact.css';
var nr=0;
function ContactForm() {
  const [state, handleSubmit] = useForm("xqkvzkry");
  if (state.succeeded) {
    if(nr===0){
      alert("Thanks For Joining :)");
      nr=1;
    }
      
      return(<h3>  We'll Contact you Soon... </h3>)
  }
  return (
    
            
      <form className="form__container" onSubmit={handleSubmit}>
      <label htmlFor="email"> Name </label>
      <input id="name" type="text" className="form__elements" placeholder='Name'/>
      <label htmlFor="email"> Email Address </label>
      <input id="email"  className="form__elements" placeholder='Email' type="email"  name="email"/>
      <label htmlFor="email"> Subject </label>
      <input id="subject" type="text" className="form__elements" placeholder='Subject'/>
      
      <ValidationError prefix="Email" field="email" errors={state.errors}/>
      <label htmlFor="email"> Message </label>
      <input id="message" name="message" type="text" className="form__elements" placeholder='Message'/>
      <ValidationError  prefix="Message"  field="message" errors={state.errors} />
      <button className='form__submit' type="submit" disabled={state.submitting}> Submit </button>
    </form>
    
  );
}
export default ContactForm;