import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-scroll";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "./navbar.css";

export default function Navbar() {
  const [Mobile, setMobile] = useState(false);
  return (
    <div className="Navbar">
      <div className="Navbar__left">
        <span className="Navbar__brand">TechSnapie </span>Solutions
      </div>

      <div
        className={Mobile ? "Navbar__mobile" : "Navbar__right"}
        onClick={() => setMobile(true)}
      >
        <ul>
          <li>
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li>
            <Link to="about" smooth={true} duration={500}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="services" smooth={true} duration={500}>
              Services
            </Link>
          </li>
          <li>
            <Link to="portfolio" smooth={true} duration={500}>
              Clients
            </Link>
          </li>
          <li>
            <div
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://blogs.techsnapie.com/";
              }}
            >
              Blogs
            </div>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="Navbar__menu-icon" onClick={() => setMobile(!Mobile)}>
        {Mobile ? <ImCross /> : <FaBars />}
      </div>
    </div>
  );
}
